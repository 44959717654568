import { LocationSearchItem } from '@deckee/api-app-main';
import { IconWithTextLockup, NavList } from '@deckee/deck-hand';
import { useTheme } from 'styled-components';
import IconCircleWrapper from '../IconCircleWrapper/IconCircleWrapper';
import BoatRamp from '../svg/boat-ramp';
import Bollard from '../svg/bollard';
import Bowser from '../svg/bowser';
import Buoy from '../svg/buoy';
import Forklift from '../svg/forklift';
import Haulout from '../svg/haulout';
import Helm from '../svg/helm';
import LocationPin from '../svg/location-pin';
import Map from '../svg/map';
import WaveOverBar from '../svg/wave-over-bar';

type SearchLocationCardItem = Pick<
  LocationSearchItem,
  'title' | 'markerType' | 'placeDescription'
>;

const deckeeMarkersConfig = {
  wharfMarker: {
    icon: <Bollard size={22} color="#fff" />,
    markerColor: '#56AC5C',
    displayName: 'Dock or wharf',
  },
  barCrossingMarker: {
    icon: <WaveOverBar size={22} color="#fff" />,
    markerColor: '#E28D18',
    displayName: 'Bar crossing',
  },
  boatRampMarker: {
    icon: <BoatRamp size={22} color="#fff" />,
    markerColor: '#56AC5C',
    displayName: 'Boat ramp',
  },
  boatStorageMarker: {
    icon: <Forklift size={22} color="#fff" />,
    markerColor: '#56AC5C',
    displayName: 'Boat storage',
  },
  fuelDockMarker: {
    icon: <Bowser size={22} color="#fff" />,
    markerColor: '#56AC5C',
    displayName: 'Fuel dock',
  },
  hauloutFacilityMarker: {
    icon: <Haulout size={22} color="#fff" />,
    markerColor: '#56AC5C',
    displayName: 'Haul-out facility',
  },
  marinaMarker: {
    icon: <Helm size={22} color="#fff" />,
    markerColor: '#56AC5C',
    displayName: 'Marina',
  },
  mooringMarker: {
    icon: <Buoy size={22} color="#fff" />,
    markerColor: '#139ECA',
    displayName: 'Mooring',
  },
  beachEmergencyNumberMarker: {
    icon: <Map size={22} color="#fff" />,
    markerColor: '#FF5C39',
    displayName: 'Beach Emergency Number',
  },
};

const getMarkerConfig = (type: string) => {
  return deckeeMarkersConfig[type];
};

interface SearchLocationCardProps extends SearchLocationCardItem {
  onSelectCard: (searchItem: SearchLocationCardItem) => void;
}

const LocationSearchCard = ({
  title,
  markerType,
  placeDescription,
  onSelectCard,
}: SearchLocationCardProps) => {
  const {
    colors: { secondary, primary },
  } = useTheme();

  const markerConfig = getMarkerConfig(markerType);

  return (
    <NavList.Item chevron onClick={onSelectCard}>
      <IconWithTextLockup
        heading={title}
        description={placeDescription || markerConfig?.displayName}
        emphasis="heading"
        useEllipsis
        image={
          markerConfig ? (
            <IconCircleWrapper bg={markerConfig.markerColor}>
              {markerConfig.icon}
            </IconCircleWrapper>
          ) : placeDescription ? (
            <IconCircleWrapper bg={primary}>
              <Map size={22} color="white" />
            </IconCircleWrapper>
          ) : (
            <IconCircleWrapper>
              <LocationPin size={20} color={secondary} />
            </IconCircleWrapper>
          )
        }
      />
    </NavList.Item>
  );
};

export default LocationSearchCard;
