import React from 'react';

const Map = ({
  size,
  color = '#484848',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    height={size}
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="M5.95734373,7.65513067 L6.06774358,7.66634811 L6.06774358,7.66634811 L6.17906254,7.69124317 L11.6017186,9.2423623 C11.8396706,9.30998146 12.0884113,9.32721201 12.3315799,9.29308193 L12.5126559,9.25783908 L13.2053127,9.08415503 C13.0736647,9.56664593 12.9867911,10.0658567 12.9491975,10.5793785 L12.9303127,10.9671655 L12.92,10.9706047 L12.92,24.9650691 L17.32,23.70629 L17.32,18.4166639 L19.08,20.8929516 L19.08,23.6822148 L24.36,25.0029015 L24.36,18.4166639 L26.12,17.0117147 L26.12,26.07424 C26.12,26.6380081 25.622921,27.0621222 25.0807715,26.9925054 L24.9718746,26.971894 L18.6726559,25.3949799 C18.4325919,25.3351088 18.1845288,25.3268418 17.9430674,25.3692061 L17.7634373,25.4104575 L12.3528127,26.9564173 C12.1887807,27.0029055 12.0180252,27.0122031 11.8513038,26.9843102 L11.7271873,26.9564173 L6.27531254,25.3984199 C5.57060846,25.1970885 5.07041307,24.5809548 5.00685237,23.860449 L5,23.7045705 L5,8.58029921 C5,8.04255959 5.44925206,7.63564191 5.95734373,7.65513067 Z M6.76,9.6894698 L6.76,23.70629 L11.16,24.9633496 L11.16,10.9465297 L6.76,9.6894698 Z M20.84,5 C24.24208,5 27,7.75935486 27,11.1632049 C27,15.2168328 22.9950373,17.1017283 22.4934373,17.5534023 C21.9803973,18.0147631 21.5572827,18.9403303 21.3865627,19.5258348 C21.3038427,19.8102227 21.07144,19.954741 20.84,19.9609051 C20.60944,19.954741 20.3761573,19.8102227 20.2934373,19.5258348 C20.1218373,18.9394499 19.6987227,18.0147631 19.1865627,17.5534023 C18.6849627,17.1017283 14.68,15.2168328 14.68,11.1632049 C14.68,7.75935486 17.43792,5 20.84,5 Z M20.84,9.40228919 C19.8676,9.40228919 19.08,10.190299 19.08,11.1632049 C19.08,12.1361108 19.8676,12.9241205 20.84,12.9241205 C21.8124,12.9241205 22.6,12.1361108 22.6,11.1632049 C22.6,10.190299 21.8124,9.40228919 20.84,9.40228919 Z"
    ></path>
  </svg>
);

Map.defaultProps = {
  color: null,
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default Map;
